.news-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  outline: none;
}

.news-box {
  max-width: 45rem; 
  border-bottom: 2px solid;
  outline: none;
  padding: 1rem;
  cursor: pointer;
  transition: height 0.3s;
  overflow: hidden;
  box-sizing: border-box; /* Include border and padding in box dimensions */
  text-align: justify; /* Align the heading to the left */
  display: flex;
  flex-direction: column;
  align-items:flex-start;
}

.news-box .category-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Arrange items in a grid of four columns */
  gap: 0.5rem; /* Add spacing between grid items */
}

.news-box.expanded {
  height: auto;
  overflow: visible; /* Show overflowing content when expanded */
}

.news-box .content-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  margin-top: 1rem;
}

.news-box.expanded .content-wrapper {
  height: auto;
  overflow: visible; /* Show overflowing content when expanded */
}

.news-box .posted-at {
  text-align: left; /* Align the postedAt data to the left */
  margin-top: 0.5rem; /* Add spacing between the title and the postedAt data */
}

.news-box .content-wrapper img {
  margin-bottom: 1rem;
}

.news-box .content-wrapper .block-content {
  text-align: justify; /* Align the body content to justify */
  max-width: 45rem; /* Set a maximum width for the content */
  margin: 0 auto; /* Center the content horizontally */
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.news-box .content-wrapper .block-content > *:not(:last-child) {
  margin-bottom: 1rem; /* Add desired spacing between content blocks */
}

#news-article-1 {
  display: flex;
  flex-direction: column;
  align-items:center;
  padding-top: 4rem;
  padding-bottom: 6rem;
  background-color:#ff3126d2;
  font-size: 45px;
}

#news-article-1 > p,
#news-article-1 > h1 {
  color: #ffffff;
}

.status {
  font-weight: 700;
  color:#ff3126;
}

@media(max-width: 540px) {
  #news-article-1 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    background-color:#ff3126c6;
    font-size: 18px;
    text-align: center;
  }

  #news-article-1 > p,
  #news-article-1 > h1 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 900;
  }

  .news-box {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding: 1rem;
  }

  .news-box .content-wrapper .block-content {
    font-size: 17px;
  }

  .news-box h2 {
    font-size: 20px;
  }

  .status {
    font-weight: 700;
    color:#ff3126;
  }

  .news-box {
    max-width: 45rem; 
    border: none;
  }
}

