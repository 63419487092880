/* General navbar classes  */
body{
    margin:0;
    padding: 0;
}
.nav-container{
    background-color: black;
    color:white;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 2px solid black;
    margin-bottom: 0rem;
}
.nav-hamburguer-icon{
    font-size: 1.2em;
    display: block;
}
.nav-hamburguer-icon:hover{
    color:grey;
}

nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
}
.nav-brand{
    margin:0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 2rem;
    font-weight: 100;
    border-right: 2px solid black;
    padding-right: 2rem;
}

.nav-links{
    display:none;
    padding-top: 1rem;
}

@media screen and (min-width: 600px) {
    .nav-links{
        width:25%;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .nav-links > a{
        color:white;
        text-decoration: none;
        font-size: 1.2em;
    }
    .nav-links > a:not(:last-child){
        margin-right: 1em;
    }
    .nav-hamburguer-icon{
        display:none;
    }
    .overlay-container-hidden{
        display: none;
    }
}

/*Overlay menu classes */
.overlay-container-show{
    overflow: hidden;
    position: fixed;
    background-color: black;
    opacity: 0.9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: left linear 500ms;
}

.overlay-container-hidden{
    overflow: hidden;
    position: fixed;
    background-color: black;
    opacity: 0.9;
    top: 0;
    right: 0;
    bottom: 0;
    left: -600px;
    width: 100%;
    z-index: 1;
    transition: left linear 500ms;
}

.overlay-buttonClose{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: .5em;
    font-size: 4em;
}

.overlay-menu-wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.overlay-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10em;
}

.overlay-menu a{
    font-size: 3em;
    font-weight: 100;
    text-decoration: none;
    color: #818181;
    transition: 0.3s;
}

.overlay-menu a:hover, .overlay-menu a:focus {
    color: #f1f1f1;
  }

.overlay-menu-item:not(:last-child){
    margin-bottom: .3em;
}

/* .slideIn-enter {
    position: absolute;
    left:-600px;
}
.slideIn-enter-active {
    position: absolute;
    left:0px;
    transition: left 800ms;
}
.slideIn-exit {
    position: absolute;
    left:0px;
}
.slideIn-exit-active {
    position: absolute;
    left:-600px;
    transition: left 800ms;
} */