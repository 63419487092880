:root {
  --background-color: rgb(255, 255, 255);
  --border-color: rgb(0, 0, 0);
   /* It's like defining functions. These variables can be resued later*/
}


h1, h2, h3, a, p, span {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 400;
  color: rgb(0, 0, 0);
  margin: 0px;
}

* {
  box-sizing: border-box;
}


a {
  text-decoration: none;
}

main {
  overflow: hidden;
}

/* Desktop*/

@media(min-width: 770px) {
  main > article {
    display: grid;
    height: 100%;
  }
  
  #article-1 {
    grid-template-columns: 2fr 1fr;
    padding-top: 2rem;
  }
  
  #article-2 {
    grid-template-columns: 1fr 2fr;
    padding-top: 2rem;
  }
  
  #article-1 #content-1 > p{
    font-size: 2rem;
    width: 40rem;
    padding-left: 5rem;
  }
  
  .yellow-underline {
    text-decoration: underline;
    text-decoration-color: #ffcf26
  }
  
  #article-1 img {
    width: 25rem;
    height: auto;
  }
  
  #scroll {
    display: flex;
  }
  
  #scroll > p {
    font-size: 2.4rem;
    padding-right: 1rem;
    padding-left: 9rem;
  }
  
  #scroll > img {
    height: 5rem;
  }
  
  #article-2 #content-2 > p{
    font-size: 2rem;
    width:100%;
    padding-right: 4rem;
  }
  
  #article-3 {
    padding-top: 3rem;
    justify-items: center;
    padding-bottom: 10rem;
  }
  
  #article-4 {
    justify-items: center;
    padding-bottom: 10rem;
  }
  
  #article-5 {
    justify-items: center;
    padding-bottom: 10rem;
  }
  
  .cool-box {
    border: 2px solid;
    box-shadow: 0px 15px 0px;
    display:grid;
    justify-items:center;
    width: 75%;
    height: 120%;
  }
  
  .cool-box > img {
    margin-top: 1rem;
    height: 7rem;
    object-fit: cover;
  }
  
  .cool-box > h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .cool-box > p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
    text-align: center;
  }
  
  .cool-box button {
    font-size: 1.5rem;
    color: aliceblue;
    background-color: #e13030;
    border-radius: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem 6rem;
  }
  
  
   
  #cool-box-1 {
    background-color: #ffcf26;
    border-radius: 5rem;
    transform: rotate(-4.85deg);
  }
  
  #cool-box-2 {
    background-color: #ffffff;
    border-radius: 5rem;
    transform: rotate(2.85deg);
  }
  
  #cool-box-3 {
    background-color: #376fff;
    border-radius: 5rem;
    transform: rotate(-4.15deg);
  }
  
  .article-6 {
    border: 2px solid;
    box-shadow: 0px 50px 0px;
    display:grid;
    justify-items:center;
    width:100%;
  }
  
  .article-6 > img {
    margin-top: 1rem;
    height: 7.5rem;
    object-fit: cover;
  }
  
  .article-6 > h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .article-6  p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .article-6 button {
    font-size: 1.5rem;
    color: aliceblue;
    background-color: #e13030;
    border-radius: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem 5rem;
  }
  
  #cool-box-1:hover{
    transition: transform 0.3s;
    transform: none;
  }
  
  #cool-box-2:hover{
    transition: transform 0.3s;
    transform: none;
  }
  
  #cool-box-3:hover{
    transition: transform 0.3s;
    transform: none;
  }

  /* Resource page */

  #resource-article-1{
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 4rem;
    padding-bottom: 6rem;
    background-color:#376fff;
    font-size: 45px;
  }

  #resource-article-1 > p,
  #resource-article-1 > h1 {
    color: #ffffff;
}


  #resource-article-2 {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items:center;
  }

  /* Event Page */

  #event-article-1 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 4rem;
    padding-bottom: 6rem;
    background-color:#ffcf26;
    font-size: 45px;
  }

  #event-article-2 {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items:center;
  }

  #event-data {
    margin-top: 2rem;
    border-top: 2px solid;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

 /* Fetched event data */

  .square-box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    max-width: 30rem;
    min-height: 15rem;
    padding: 1rem;
    
  }

  .imgDesc {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
  }

  .descriptionContainer {
    overflow: hidden;
    word-wrap: break-word;
  }

  .dateAndUser {
    color: #8b8989;
    font-weight:lighter;
    font-style: italic;
    padding-right: 1rem;
    font-size: 1.5rem;
  }

  #event-post-article .block-content-wrapper {
    margin-top: 4rem;
    font-size: 1.5rem;
  }
  
  .custom-block-content p {
    margin-bottom: 1.5em; /* Adjust the spacing as needed */
  }  
  

  #event-post-article {
    display:flex;
    flex-direction: column;
    margin-top: 0rem;
    margin-left: 20rem;
    margin-right: 20rem;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
  }


  /*Date and college */

  #details > span {
    color: #555;
    font-weight:lighter;
    font-style: italic;
    padding-right: 1rem;
  }

 /* --------end of event page------- */


/* community page */

 #community-article-1 {
  display: flex;
  flex-direction: column;
  align-items:center;
  padding-top: 4rem;
  padding-bottom: 6rem;
  background-color: black;
  font-size: 45px;
  text-align: center;
 }

 #community-article-1 > p,
  #community-article-1 > h1 {
    color: #ffffff;
}

#community-article-2 {
  display: flex;
  flex-direction: column;
  align-items:center;
  padding-top: 3rem;
  padding-bottom: 6rem;
  margin: 2rem;
  font-size: 18px;
  text-align: center;
}

/* -------end of community page------- */

  .dropdown-container{
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 12rem;
    width: 60rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  /* contributor page */

  #contributor-article-1{
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 4rem;
    padding-bottom: 6rem;
    background-color:#376fff;
    font-size: 45px;
  }

  #contributor-article-1 > p,
  #contributor-article-1 > h1 {
    color: #ffffff;
}

  #contributor-article-2 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    margin: 2rem;
    font-size: 18px;
    text-align: center;
  }

  .contributor {
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 28rem;
    width: 25rem;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 1rem;
  }

  .content-container {
    display: flex;
    align-items: center;
  }
  
  #content {
    flex-grow: 1;
    margin-right: 20px;
  }
  
  .small-image {
    height: 10rem;
    width: 10rem;
  }



  
  /* end of contributor page */

  .form-button {
    background-color: #ffcf26;
    margin-left: 2.5rem;
    margin-top: 1rem;
    border-radius: 15px;
    border:  2px solid;
    border-color: black;
    padding: 0.6rem;
    font-weight: 700;
  }

  select {
    font-weight: 600;
    border-radius: 4px;
    padding: 0.3rem;
    width: 15rem;
    border: none;
    background-color: #ffffff;
    font-size: 16px;
  }

  .renderedData {
    margin-top: 2rem;
    border-top: 2px solid;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  .renderedData > div{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 8rem;
    width: 25rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .renderedData > div > h3 {
    margin-top: -1.5rem;
    font-size: 25px;
    font-weight: 900;
  }

  #dateAndUser > span {
    color: #555;
    font-weight:lighter;
    font-style: italic;
    padding-right: 1rem;
  }

  #dateAndUser {
    display: flex;
    flex-direction: row;
  }
}

@media(max-width: 768px) {
  main > article {
    display: grid;
    height: 100%;
  }
  
  #article-1 {
    grid-template-columns: 2fr 1fr;
    padding-top: 2rem;
  }
  
  #article-2 {
    grid-template-columns: 1fr 2fr;
    padding-top: 2rem;
  }
  
  #article-1 #content-1 > p{
    font-size: 2rem;
    width: 25rem;
    padding-left: 2rem;
  }
  
  .yellow-underline {
    text-decoration: underline;
    text-decoration-color: #ffcf26
  }
  
  #article-1 img {
    width: 25rem;
    height: auto;
  }
  
  #scroll {
    display: flex;
  }
  
  #scroll > p {
    font-size: 2.4rem;
    padding: 1rem 4rem;
  }
  
  #scroll > img {
    height: 5rem;
  }
  
  #article-2 #content-2 > p{
    font-size: 2rem;
    width:100%;
    padding-right: 2rem;
    line-height: normal;
  }
  
  #article-3 {
    padding-top: 3rem;
    justify-items: center;
    padding-bottom: 6rem;
  }
  
  #article-4 {
    justify-items: center;
    padding-bottom: 6rem;
  }
  
  #article-5 {
    justify-items: center;
    padding-bottom: 6rem;
  }
  
  .cool-box {
    border: 2px solid;
    box-shadow: 0px 15px 0px;
    display:grid;
    justify-items:center;
    width: 90%;
  }
  
  .cool-box > img {
    margin-top: 1rem;
    height: 7rem;
    object-fit: cover;
  }
  
  .cool-box > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .cool-box > p {
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
    text-align: center;
  }
  
  .cool-box button {
    font-size: 1.5rem;
    color: aliceblue;
    background-color: #e13030;
    border-radius: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem 6rem;
  }
  
  
   
  #cool-box-1 {
    background-color: #ffcf26;
    border-radius: 5rem;
    transform: rotate(-4.85deg);
  }
  
  #cool-box-2 {
    background-color: #ffffff;
    border-radius: 5rem;
    transform: rotate(2.85deg);
  }
  
  #cool-box-3 {
    background-color: #376fff;
    border-radius: 5rem;
    transform: rotate(-4.15deg);
  }
  
  .article-6 {
    border: 2px solid;
    box-shadow: 0px 50px 0px;
    display:grid;
    justify-items:center;
    width:100%;
  }
  
  .article-6 > img {
    margin-top: 1rem;
    height: 7.5rem;
    object-fit: cover;
  }
  
  .article-6 > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .article-6  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .article-6 button {
    font-size: 1.5rem;
    color: aliceblue;
    background-color: #e13030;
    border-radius: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem 5rem;
  }
  
  #cool-box-1:hover{
    transition: transform 0.3s;
    transform: none;
  }
  
  #cool-box-2:hover{
    transition: transform 0.3s;
    transform: none;
  }
  
  #cool-box-3:hover{
    transition: transform 0.3s;
    transform: none;
  }

  .dropdown-container{
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 12rem;
    width: 18rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .form-button {
    background-color: #ffcf26;
    margin-left: 2.5rem;
    margin-top: 1rem;
    border-radius: 15px;
    border:  2px solid;
    border-color: black;
    padding: 0.6rem;
    font-weight: 700;
  }

  select {
    font-weight: 600;
    border-radius: 4px;
    padding: 0.3rem;
    width: 10rem;
    border: none;
    background-color: #ffffff;
    font-size: 16px;
    color: #555;
  }

  .renderedData {
    margin-top: 2rem;
    border-top: 2px solid;
  }
  .renderedData > div{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 8rem;
    width: 23rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .renderedData > div > h3 {
    margin-top: -1.5rem;
    font-size: 25px;
    font-weight: 900;
  }

  #dateAndUser > span {
    color: #555;
    font-weight:lighter;
    font-style: italic;
    padding-right: 1rem;
  }

  #dateAndUser {
    display: flex;
    flex-direction: row;
  }
  
}


/* Mobile */
@media(max-width: 540px) {
  main > article {
    display: grid;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  #article-1 #images{
    display: none;
  }
  
  #article-1 #content-1 > p{
    font-size: 1.5rem;
    width: 20rem;
    font-weight: 600;
    padding: 0rem 1rem;
  }
  
  .yellow-underline {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #ffcf26
  }
  
  #article-2 {
    grid-template-columns: 2fr 2fr;
  }
  
  #scroll {
    display: flex;
  }
  
  #scroll > p {
    font-size: 2rem;
    padding: 0rem 1rem;
  }
  
  #scroll > img {
    height: 4rem;
  }
  
  #article-2 #content-2 > p{
    font-size: 1rem;
    padding-right: 1rem;
    line-height: 1.5rem;
  }

  #article-3 {
    padding-top: 3rem;
    justify-items: center;
    padding-bottom: 3rem;
  }
  
  #article-4 {
    justify-items: center;
    padding-bottom: 3rem;
  }
  
  #article-5 {
    justify-items: center;
    padding-bottom: 3rem;
  }
  
  .cool-box {
    border: 2px solid;
    box-shadow: 0px 15px 0px;
    display:grid;
    justify-items:center;
    width: 100%;
  }
  
  .cool-box > img {
    margin-top: 1rem;
    height: 7rem;
    object-fit: cover;
  }
  
  .cool-box > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .cool-box > p {
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
    text-align: center;
  }
  
  .cool-box button {
    font-size: 1.5rem;
    color: aliceblue;
    background-color: #e13030;
    border-radius: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem 6rem;
  }
  
  #cool-box-1 {
    background-color: #ffcf26;
    border-radius: 5rem;
    transform: rotate(-4.85deg);
  }
  
  #cool-box-2 {
    background-color: #ffffff;
    border-radius: 5rem;
    transform: rotate(2.85deg);
  }
  
  #cool-box-3 {
    background-color: #376fff;
    border-radius: 5rem;
    transform: rotate(-4.15deg);
  }
  
  .article-6 {
    border: 2px solid;
    box-shadow: 0px 50px 0px;
    display:grid;
    justify-items:center;
    width:100%;
  }
  
  .article-6 > img {
    margin-top: 1rem;
    height: 7.5rem;
    object-fit: cover;
  }
  
  .article-6 > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .article-6  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
   
  .article-6 button {
    font-size: 1.5rem;
    color: aliceblue;
    background-color: #e13030;
    border-radius: 2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem 5rem;
  }
  
  #cool-box-1:hover{
    transition: transform 0.3s;
    transform: none;
  }
  
  #cool-box-2:hover{
    transition: transform 0.3s;
    transform: none;
  }
  
  #cool-box-3:hover{
    transition: transform 0.3s;
    transform: none;
  }

    /* resource page */

  #resource-article-2 {
    display: flex;
    flex-direction: column;
    align-items:center;

  }

  #resource-article-1{
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    background-color:#376fff;
    font-size: 18px;
    text-align: center;
  }

  #resource-article-1 > p,
  #resource-article-1 > h1 {
    color: #ffffff;
}

  .dropdown-container{
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 12rem;
    width: 18rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .form-button {
    background-color: #ffcf26;
    margin-left: 2.5rem;
    margin-top: 1rem;
    border-radius: 15px;
    border:  2px solid;
    border-color: black;
    padding: 0.6rem;
    font-weight: 700;
  }

  select {
    font-weight: 600;
    border-radius: 4px;
    padding: 0.3rem;
    width: 10rem;
    border: none;
    background-color: #ffffff;
    font-size: 16px;
    color: #555;
  }

  .renderedData {
    margin-top: 2rem;
    border-top: 2px solid;
  }
  .renderedData > div{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    height: 8rem;
    width: 23rem;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .renderedData > div > h3 {
    margin-top: -1.5rem;
    font-size: 25px;
    font-weight: 900;
  }

  #dateAndUser > span {
    color: #555;
    font-weight:lighter;
    font-style: italic;
    padding-right: 1rem;
  }

  #dateAndUser {
    display: flex;
    flex-direction: row;
  }

/* end of resource page */

/* events page */

  #event-article-1 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    background-color:#ffcf26;
    font-size: 18px;
    text-align: center;
  }

  .square-box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    min-height: 8rem;
    max-width: 22rem;
    display:flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }

  .imgDesc {
    margin-top: 1rem;
  }

  #details > span {
    color: #555;
    font-weight:lighter;
    font-style: italic;
    padding-right: 1rem;
  } 

  #event-post-article {
    padding-top: 0rem;
    display: flex;
    flex-direction: column;
    align-items:center;
    padding: 1.5rem;
    text-align: justify;
  }

  #event-post-article .block-content-wrapper {
    margin-top: 2rem;
    font-size: 16px;
  }
  
  .custom-block-content p {
    margin-bottom: 1.5em; /* Adjust the spacing as needed */
  } 

    /* end of event page */

  /* community page */

  #community-article-1 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    background-color:black;
    font-size: 18px;
    text-align: center;
   }
  
   #community-article-1 > p,
    #community-article-1 > h1 {
      color: #ffffff;
  }

  #community-article-2 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 3rem;
    padding-bottom: 6rem;
    margin: 2rem;
    font-size: 18px;
    text-align: center;
  }
    /* end of community page */

  #contributor-article-1 {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 4rem;
    padding-bottom: 6rem;
    background-color:#376fff;
    font-size: 28px;
  }

  #contributor-article-1 > p,
  #contributor-article-1 > h1 {
    color: #ffffff;
  }

  .contributor {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-color: #ffffff;
    border: 2px solid;
    border-radius: 15px;
    box-shadow: 9px 8px 0px;
    min-height: 8rem;
    max-width: 22rem;
    display:flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
}
